<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

        <!-- At a glance -->
        <h4>At A Glance</h4>
        <div class="row mb-4 g-2 g-md-4">

            <!-- Drafts -->
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                <div class="info-card">
                    <span class="info-card-tooltip" id="draft-tooltip">
                        <font-awesome-icon icon="question-circle"/>
                        <b-tooltip target="draft-tooltip">Drafts are your saved surveys that have not yet been published.</b-tooltip>
                    </span>
                    <h6>Draft Surveys</h6>
                    <p class="large-number">{{ dashboard.drafts }}</p>
                    <router-link to="/profile/surveys/drafts" class="info-card-link" title="View your draft posts">View drafts</router-link>
                </div>
            </div>

            <!-- Active surveys -->
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                <div class="info-card">
                    <span class="info-card-tooltip" id="active-tooltip">
                        <font-awesome-icon icon="question-circle"/>
                        <b-tooltip target="active-tooltip">Active posts are your published surveys that can collect responses.</b-tooltip>
                    </span>                    
                    <h6>Active Surveys</h6>
                    <p class="large-number">{{ dashboard.active }}</p>
                    <router-link to="/profile/surveys/active" class="info-card-link" title="View your active posts">View active surveys</router-link>
                </div>
            </div>

            <!-- Total responses -->
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                <div class="info-card">
                    <span class="info-card-tooltip" id="total-tooltip">
                        <font-awesome-icon icon="question-circle"/>
                        <b-tooltip target="total-tooltip">The total number of responses that your surveys have collected.</b-tooltip>
                    </span>                      
                    <h6>Total Responses</h6>
                    <p class="large-number">{{ dashboard.respondents }}</p>
                    <p class="info-card-link" title="View a summarized report of your posts">Responses</p>
                </div>
            </div>

            <!-- Avg. response rate -->
            <div class="col-lg-3 col-md-6 mb-3 mb-md-3 col-6 mb-lg-0">
                <div class="info-card">
                    <span class="info-card-tooltip" id="response-rate-tooltip">
                        <font-awesome-icon icon="question-circle"/>
                        <b-tooltip target="response-rate-tooltip">Average response rate is a result of the number of responses divided by the number of number of people who have clicked on your surveys.</b-tooltip>
                    </span>                      
                    <h6>Avg. Response Rate</h6>
                    <p class="large-number">
                        {{ `${dashboard.respondent_rate}%` }}
                    </p>
                    <p class="info-card-link" title="View a summarized report of your posts">Response Rate</p>
                </div>
            </div>

        </div><!-- End row -->

        <!-- Recent Activity (Existing User)-->
        <h4>Recent Activity</h4>
        <div class="row mb-4">
            <div class="col-lg-12">

                <!-- Empty store shows for new user -->
                <div v-if="recent == 0" class="posts-empty">
                    <div class="posts-empty-inner">
                        <div class="posts-empty-inner_img">
                            <img loading="lazy" src="@/assets/img/empty-box.png" width="80" height="80"
                                alt="Empty box"/>
                        </div>
                        <h4>No recent activity</h4>
                        <h6 class="mb-4">Your most recent posts will appear here.</h6>
                        <button class="custom-btn custom-btn-primary" v-b-modal.create-post-modal>
                            <span>Create Your First Post</span>
                        </button>                        
                    </div>
                </div>                
                
                <div v-else class="info-card info-card-br-diff p-0">
                    <ul class="r-list">
                        <li v-for="survey in recent" :key="survey.code" class="r-list-item-container">
                            <div class="r-list-item">
                                <div class="r-list-item-title">
                                    <h5>
                                        <router-link :to="'/profile/surveys/view/'+ survey.code +'/questionnaire'">
                                        {{ survey.title }}
                                        </router-link>
                                    </h5>
                                    <span class="subtitle">{{
                                            moment(survey.created_at).format("MMM D, YYYY HH:mm")
                                        }}</span>
                                </div>
                                <ul class="r-list-item-info">
                                    <li>
                                        <div class="r-list-item-info__data">
                                            <span :class="'post-status ' + survey.status">{{ survey.status }}</span>
                                            <span>Status</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="r-list-item-info__data">
                                            <span>{{ survey.respondents_count }}</span>
                                            <span>Responses</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="r-list-item-info__data">
                                            <span>{{ survey.questions_count }}</span>
                                            <span>Questions</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="r-list-item-info__data">
                                            <span :class="survey.visibility == 'public' ? 'public' : 'private' ">
                                                <font-awesome-icon :icon="survey.visibility == 'public' ? 'lock-open' : 'lock'" />
                                                {{ survey.visibility }}
                                            </span>
                                            <span>Visibility</span>
                                        </div>
                                    </li>
                                </ul><!-- End list item info -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Active surveys & explore surveys -->
        <h4>Browse Posts</h4>
        <div class="row mb-4">

            <!-- Active surveys -->
            <div class="col-lg-6 col-md-12 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card info-card-list">
                    <div class="info-card-list-header">
                        <h6>Your Active Posts</h6>
                        <router-link v-if="mySurveys.count == 5" to="/profile/surveys">View All</router-link>
                    </div>
                    <div v-if="mySurveys == 0">
                        <p class="mb-0">No active surveys</p>
                    </div>
                    <ul>
                        <li v-for="survey in mySurveys" :key="survey.id">
                            <router-link class="info-card-list-title" :to="`/profile/surveys/view/${survey.code}/questionnaire`">
                                {{ survey.title }}
                            </router-link>
                            <span class="info-card-list-count">
                                {{ survey.respondents_count == 1 ? survey.respondents_count + ' response' : survey.respondents_count + ' responses' }}
                            </span>
                        </li>
                    </ul>

                </div>
            </div>

            <!-- Explore surveys -->
            <div v-if="auth.id != 390" class="col-lg-6 col-md-12 mb-3 mb-md-3 mb-lg-0">
                <div class="info-card info-card-list">
                    <div class="info-card-list-header">
                        <h6>Explore Posts</h6>
                        <a href="/surveys">View More</a>
                    </div>
                    <div v-if="explore == 0">
                        <p class="mb-0">Set your content preferences to explore public posts.</p>
                    </div>
                    <ul>
                        <li v-for="survey in explore" :key="survey.id">
                            <router-link class="info-card-list-title" :to="'/surveys/'+ survey.code +'/'+ survey.slug">
                                {{ survey.title }}
                            </router-link>
                            <span class="info-card-list-count">
                                {{ survey.respondents_count == 1 ? survey.respondents_count + ' response' : survey.respondents_count + ' responses' }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <!-- End row -->
    </div>
    <!-- End wrapper -->
</template>

<script>

export default {
    name: "Dashboard",
    props: ['code', 'auth'],

    data() {
        return {
            recent: [],
            explore: [],
            mySurveys: [],

            dashboard: {
                drafts: 0,
                active: 0,
                respondents: 0,
                respondent_rate: 0,
                respondent_trend: 'up'
            }
        }
    },
    mounted() {
        this.loadDashboard()
        this.loadRecentSurveys()
        this.loadTopSurveys()
        this.loadMySurveys()
    },
    methods: {
        loadDashboard() {
            this.$http.get('/api/stats').then(res => {
                this.dashboard = res.data.data
            })
        },
        loadRecentSurveys() {
            this.$http.get('/api/surveys/recent?recent=2').then(res => {
                this.recent = res.data.data
            })
        },
        loadTopSurveys() {
            this.$http.get('/api/surveys/top?top=5').then(res => {
                this.explore = res.data.data
            })
        },
        loadMySurveys() {
            this.$http.get('/api/profile/surveys/top?top=5').then(res => {
                this.mySurveys = res.data.data
            })
        }
    }
}
</script>

<style scoped>
</style>